import { PureComponent } from 'react'
import { AppState, AppStateStatus } from 'react-native'
import { connect } from 'react-redux'

import { DeviceActions, DeviceThunks } from 'store/Device'
import { OrderActions } from 'store/Orders'
import { SettingActions } from 'store/Settings'
import { UserActions } from 'store/User'

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

interface State {
  appState: AppStateStatus
}

class StoreManager extends PureComponent<Props, State> {
  state = {
    appState: AppState.currentState,
  }

  changeEventSubscription = null

  componentDidMount() {
    this.changeEventSubscription = AppState.addEventListener(
      'change',
      this.handleAppStateChange,
    )
  }

  componentWillUnmount() {
    if (this.changeEventSubscription) {
      this.changeEventSubscription.remove()
    }
  }

  handleAppStateChange = (nextAppState) => {
    if (
      this.state.appState.match(/inactive|background/) &&
      nextAppState === 'active'
    ) {
      this.updateData()
    }

    this.setState({ appState: nextAppState })
  }

  updateData() {
    this.props.fetchDevice()
    this.props.fetchCategories()
    this.props.fetchOrders()
    this.props.fetchUser()
  }

  render() {
    return null
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCategories: () => dispatch(SettingActions.fetch()),
    fetchOrders: () => dispatch(OrderActions.fetch()),
    fetchUser: () => dispatch(UserActions.fetchAccount()),
    fetchDevice: () => dispatch(DeviceThunks.fetchCurrent()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreManager)
